<!--
 * @Author: 刘格优
 * @Date: 2020-03-30 19:57:02
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-03-31 15:36:37
 -->

<template>
  <div>
    <div id="content"></div>
  </div>
</template>

<script>
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      showhttp: '',
      ossurl: '',
    };
  },

  created () {
    this.ossurl = getStorage('ossurl', '')
    this.showhttp = this.$route.query.showhttp ? this.$route.query.showhttp : ''

  },
  mounted () {
    this.$nextTick(() => {
      $('#content').load(this.ossurl + this.showhttp)
    })
  },

  methods: {
  }
}

</script>
<style scoped>
</style>